import { React } from "react";
import "../styles/categories.css"; // Importing custom CSS for animations

export default function Categories() {
    const artists = [
        "/driemo.webp",
        "/malinga.jpg",
        "/driemo.png",
        "/afana-ceez.jpg",
    ];

    const genres = [
        "/dance.png",
        "/dancehal.png",
        "/gospel.png",
        "/HipHop.png",
        "/reggae.png",
        "/RnB.png",
        "/afro.png",
    ];

    const repeatedGenres = Array(20).fill(genres).flat();
    const repeatedArtists = Array(20).fill(artists).flat();

    return (
        <div className="w-full flex flex-col items-center justify-center overflow-hidden">
            {/* Top row (scrolls to the right) */}
            <div className="scrolling-row-left w-full flex gap-x-2 md:gap-x-4 items-center my-1 md:my-3">
                {repeatedArtists.map((artist, index) => (
                    <img
                        key={index}
                        src={artist}
                        alt="mixes"
                        className="w-[30vh] h-[20vh] md:w-[65vh] md:h-[35vh] rounded-3xl object-cover object-center"
                    />
                ))}
            </div>

            {/* Bottom row (scrolls to the left) */}
            <div className="scrolling-row-right w-full flex gap-x-2 md:gap-x-4 items-center my-1 md:my-3">
                {repeatedGenres.map((genre, index) => (
                    <img
                        key={index}
                        src={genre}
                        alt="mixes"
                        className="w-[70%] md:w-[30%] h-auto rounded-3xl object-cover"
                    />
                ))}
            </div>
        </div>
    );
}
