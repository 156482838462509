import { React } from "react"
import Welcome from "../components/welcome"
import Features from "../components/features"
import Banner from "../components/banner"
import Footer from "../components/footer"
import Categories from "../components/categories"

export default function Home() {
    return (
        <>
            <div className="w-full md:w-[1200px] mx-auto overflow-x-hidden">

                <Welcome />
                <Features />
                <Banner />
            </div>

            <div className="w-full flex flex-col mx-auto mt-10">

                <Categories />

                <Footer />
            </div>
        </>
    )
}