import {React} from "react";

export default function Banner() {
    return (
        <div className="w-full h-[50vh] md:h-[75vh] relative px-2 md:px-0">
            <img src="/speaker.jpg" alt="banner" className="w-full h-full object-cover rounded-3xl" />
            <div className="absolute top-0 left-0 w-full h-full flex flex-col items-start justify-center px-6 md:px-8 text-white text-2xl md:text-5xl font-bold">
                <h1 className="text-left text-3xl md:text-5xl font-inter">Superior Audio Quality</h1>
                <p className="text-sm md:text-2xl font-inter font-normal md:font-light md:w-[70vh]">
                    We believe music should be more than just background sound -- it should be a full experience.
                    Our dedication to Hi-Fi streaming ensures crystal-clear sound, letting you hear every detail 
                    exactly as it was meant to be heard.
                </p>
            </div>
        </div>
    )
}