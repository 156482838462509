import {React} from "react";
import MixesRadio from "./features/mixes_radio";
import MusicDiscography from "./features/music_discography";
import LiveRadios from "./features/live_radios";

export default function Features() {
    return (
        <div className="w-full flex flex-col items-center justify-center px-4 md:px-0">
            <MixesRadio />
            <MusicDiscography />
            <LiveRadios />
        </div>
    )
}