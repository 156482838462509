import { React } from "react";

export default function Welcome() {
    return (
        <div className="w-full flex flex-col items-center justify-center mx-auto my-4 md:my-20">

            <div className="my-8">

                <div className="bg-black rounded-xl w-20 h-20">
                    <img src="/icon.png" alt="logo" className="w-full h-full" />
                </div>


            <h1 className="text-black text-2xl font-inter font-light text-center">Mingoli</h1>

            </div>


            <h1 className="text-black text-6xl w-[35vh] md:w-full font-inter font-semibold text-center">Tune in, Zone out.</h1>

            <p className="text-black text-xl text-center font-inter font-normal w-[35vh] my-6">Discover your sound. Let the music take over</p>
            <a href="#">
            <div className="w-fit bg-blue-600 rounded-full px-6 py-3 shadow-lg mx-auto text-white text-lg font-inter cursor-pointer transition-all duration-300 hover:px-12">Get this App</div>
            </a>
            <div className="w-full md:w-[40%] my-4 mx-auto">
                <img src="/playing.png" alt="banner" className="w-full h-auto" />
            </div>

        </div>
    )
}