import {React} from "react";

export default function MusicDiscography() {
    return (
        <>
            <div className="flex flex-col-reverse md:flex-row items-center justify-between rounded-3xl mx-auto my-8 shadow-xl bg-[#e6e8e6] bg-opacity-50">

            <div className="w-[80%] md:w-[30%] mx-auto md:pt-16">
                    <img src="/afro-mockup.png" alt="mixes" className="w-full h-auto rounded-t-3xl object-cover" />
                </div>

                <div className="w-full md:w-[60%] flex flex-col items-start justify-start pt-8 md:pt-0 px-4 md:px-16">
                    <h1 className="text-black text-4xl md:text-6xl font-inter font-semibold w-[30vh]">Music Discography.</h1>
                    <p className="text-black text-md md:text-xl font-inter font-light text-left w-[42vh] py-2 md:py-4">
                        Find your next breakout artist or favorite song in our expansive music library
                    </p>
                </div>
                
            </div>
        </>
    )
}