import {React} from "react";

export default function MixesRadio() {
    return (
        <>
            <div className="w-full flex flex-col md:flex-row items-center justify-between rounded-3xl mx-auto my-8 shadow-xl bg-[#e6e8e6] bg-opacity-50">

                <div className="w-full md:w-1/2 flex flex-col items-start md:justify-start px-4 md:px-16">
                    <h1 className="text-black text-4xl md:text-6xl font-inter font-semibold w-[25vh] md:w-[30vh] pt-8 md:pt-0">Mixes and Radio.</h1>
                    <p className="text-black text-md md:text-xl font-inter font-light text-left md:w-[42vh] py-2">
                        Discover a personalized playlist tailored to your music preferences with My Mix, or explore fresh tunes through Artist Radio
                    </p>
                </div>

                <div className="w-[80%] md:w-[30%] mx-auto pt-8 md:pt-16">
                    <img src="/mixes.png" alt="mixes" className="w-full h-auto rounded-t-3xl object-cover" />
                </div>
                
            </div>
        </>
    )
}