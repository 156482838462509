import {React} from "react";


export default function LiveRadios() {

    return (
        <>
            <div className="w-full flex flex-col md:flex-row items-center justify-between rounded-3xl mx-auto my-8 shadow-xl bg-[#e6e8e6] bg-opacity-50">

                <div className="w-full md:w-1/2 flex flex-col items-start justify-start pt-8 md:pt-0 px-4 md:px-16">
                    <h1 className="text-black text-4xl md:text-6xl font-inter font-semibold w-[15vh] md:w-[25vh]">Live Radios.</h1>
                    <p className="text-black text-md md:text-xl font-inter font-light text-left w-[48vh] py-2 md:py-4">
                       Tune in to radio stations for real-time updates. Hear the latest news as it unfolds, directly from the source.
                       Get informed about breaking news, current events, and live sports coverage. Stay up-to-date with the latest 
                       information on topics that matter to you
                    </p>
                </div>

                <div className="w-[80%] md:w-[30%] mx-auto md:pt-16">
                    <img src="/radio.png" alt="mixes" className="w-full h-auto rounded-t-3xl object-cover" />
                </div>
                
            </div>
        </>
    )
}